import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl, AbstractControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {IPerson} from './IPerson'
import { HttpClient } from '@angular/common/http';
import { Content } from '@angular/compiler/src/render3/r3_ast';
declare function SendEmail(obj):any; 


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'], 
})
export class AppComponent implements OnInit {
  //app password for device - nnmcrkuktcedaeti
//     client = new SMTPClient({
//     user: 'pankajkumarhbti@gmail.com',
//     password: 'nnmcrkuktcedaeti',
//     host: 'smtp.gmail.com',
//     ssl: true,
// });
    title = 'RBHWeb';  
    contactForm:FormGroup;
    person:IPerson;
    displayValue="none";
    counter:number = 0;
    showToastClass='toast hide';

   constructor(private formBuilder: FormBuilder,private httpClient: HttpClient) { }
   ngOnInit() {
    this.contactForm = this.formBuilder.group({
      personName: [null,[Validators.required]],
      personEmail: [null,[Validators.required]],
      likeChkBox: [null],
      personMessage: [null,[Validators.required]],
  
    });
  }
  onSubmit() {    
    const person: IPerson = {
      personName: this.contactForm.get('personName').value,
      personEmail: this.contactForm.get('personEmail').value,
      likeChkBox: this.contactForm.get('likeChkBox').value,
      personMessage: this.contactForm.get('personMessage').value,
    }
    //this.personService.addPerson(person);
    // this.httpClient.post<IPerson>("url", person).subscribe(
    //   res => {
    //     console.log(res);
    //   },
    //   error=>{this.onError(error)}
    // );
   
    SendEmail({
      Host : "smtp.gmail.com",
      Username : "pankajkumarhbti@gmail.com",
      Password : "nnmcrkuktcedaeti",
      To : person.personEmail,
      From : "tech@merge2tech.online",
      Subject : "Email enquiry on website Merge2Tech.online from Mr. "+person.personName,
      Body : person.personMessage
  }).then(
    message => alert(message)
  );  
  this.showToastClass='toast show';
  this.displayValue="block";
  this.contactForm.reset({personName:"",personEmail:"",likeChkBox:false,personMessage:""});  
    //alert("Your message sent successfully.")
  }

  w3_open() {
    // var x = document.getElementById("mySidebar");
    // x.style.width = "300px";
    // x.style.paddingTop = "10%";
    // x.style.display = "block";
  }
  hideToast()
  {
    this.showToastClass='toast hide';
    this.displayValue="none" ;
  }
  
  // Close side navigation
   w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }
  
  // Used to toggle the menu on smaller screens when clicking on the menu button
   openNav() {
    var x = document.getElementById("navDemo");
    if (x.className.indexOf("w3-show") == -1) {
      x.className += " w3-show";
    } else { 
      x.className = x.className.replace(" w3-show", "");
    }
  }


}

